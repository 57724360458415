select, option {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand{
  display: none;
}

select {
  font-family: $font-family-base;
}
