.o-nav {
  flex-grow: 1;

  body:not(.p-home) & {
    @include laptop {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    
    @include largeDesktop {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  &__sub {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 60px;

    body:not(.p-home) & {
      margin-bottom: 20px;
      
      @include laptop {
        margin-top: 0;
      }
      
      @include largeDesktop {
        margin-bottom: 0;
      }
    }

    @include mobile {
      flex-direction: row;
      align-items: center;
    }
  }
  
  &__main {
    display: none;

    @include laptopDown {
      position: absolute;
      left: $grid-gutter-width/2 * (-1);
      right: $grid-gutter-width/2 * (-1);
      top: 0;
      z-index: 4;
      background-color: rgba($black, .97);
    }

    @include laptop {
      display: block;
    }
    
    &.active {
      display: block;
    }
    
    &-x {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 30px;
      height: 30px;
      
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: $white;
      }
      
      &:before {
        transform: rotate(45deg) translate(-10px, -10px);
      }
      
      &:after {
        transform: rotate(-45deg) translate(10px, -10px);
      }
      
      @include laptop {
        display: none;
      }
    }
    
    > ul {
      @include laptopDown {
        padding: 40px 20px 2000px;
      }
      
      @include laptop {
        display: flex;
        align-items: center;
      }
      
      > li {
        position: relative;
        
        &:not(:last-child) {
          margin-right: 15px;


          @include laptopDown {
            margin-right: 0;
            border-bottom: 1px solid rgba($white, .3);
          }
        }
        
        a {
          display: block;
          text-transform: uppercase;
          font-size: .8125em; //13px
          color: $white;
          font-weight: bold;
        }
        
        > a {
          position: relative;
          
          body:not(.p-home) & {
            @include laptop {
              color: $color-text-neutral;
            }
          }

          @include laptopDown {
            padding: 10px 0;
          }
          
          @include laptop {
            
          }
          
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }

        &:hover {
          > a {
            color: $brand-primary!important;
          }

          .sub-nav {
            @include laptop {
              transform: translate(0, 0);
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
        
        .mobile-trigger {
          position: absolute;
          top: 0;
          right: 0;
          color: $white;
          font-weight: normal;
          font-size: 2em;
          height: 35px;
          width: 50px;
          text-align: right;
          z-index: 5;
          
          > span {
            display: inline-block;
            transform: rotate(0);
            @include transition(transform .3s ease);
          }
          
          @include mobile {
            width: 100px;
          }
          
          @include laptop {
            display: none;
          }
        }
        
        .mobile-trigger-input {
          
          &:checked {

            ~ .sub-nav {
              display: block;
            }

            ~ .mobile-trigger > span {
              transform: rotate(45deg);
            }
          }
        }
        
        > .sub-nav {
          display: none;
          
          @include laptop {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            padding-top: 20px;
            transform: translate(0, 20px);
            opacity: 0;
            pointer-events: none;
            @include transition(transform .3s ease, opacity .3s ease);
          }
          
          > ul {
            position: relative;
            padding: 10px;
            background-color: $brand-primary;

            @include laptop {
              padding: 20px;
              border-radius: 12px;
              white-space: nowrap;
            }

            &:before {
              bottom: 100%;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-bottom-color: $brand-primary;
              border-width: 10px;
              margin-left: -10px;

              @include laptop {
                border-width: 16px;
                margin-left: -16px;
              }
            }

            li {

              &:not(:last-child) {

                a {
                  margin-bottom: 10px;
                  border-bottom: 1px solid rgba($white, .5);
                }
              }
            }

            a {
              padding: 5px;

              @include laptop {
                padding: 10px 5px;
              }
              
              &:hover {
                color: lighten($brand-primary, 52%);
              }
            }
          }
        }
      }
    }
  }
}
