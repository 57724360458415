//== Media queries mixins
// Extra small
@mixin smallMobileDown {
  @media only screen and (min-width: $screen-zero) and (max-width: $screen-xs-minus1) {
    @content;
  }
}

// Extra small
@mixin smallMobileOnly {
  @media only screen and (min-width: $screen-xs) and (max-width: $screen-sm-minus1) {
    @content;
  }
}

// Extra small
@mixin smallMobile {
  @media only screen and (min-width: $screen-xs) {
    @content;
  }
}

// Small
@mixin mobileDown {
  @media only screen and (max-width: $screen-sm-minus1) {
    @content;
  }
}

// Small
@mixin mobileOnly {
  @media only screen and (min-width: $screen-sm) and (max-width: $screen-md-minus1) {
    @content;
  }
}

// Small
@mixin mobile {
  @media only screen and (min-width: $screen-sm) {
    @content;
  }
}

// Medium
@mixin tabletDown {
  @media only screen and (max-width: $screen-md-minus1) {
    @content;
  }
}

// Medium
@mixin tabletOnly {
  @media only screen and (min-width: $screen-md) and (max-width: $screen-mdp-minus1) {
    @content;
  }
}

// Medium
@mixin tablet {
  @media only screen and (min-width: $screen-md) {
    @content;
  }
}

// Medium plus
@mixin laptopDown {
  @media only screen and (max-width: $screen-mdp-minus1) {
    @content;
  }
}

// Medium plus
@mixin laptopOnly {
  @media only screen and (min-width: $screen-mdp) and (max-width: $screen-lg-minus1) {
    @content;
  }
}

// Medium plus
@mixin laptop {
  @media only screen and (min-width: $screen-mdp) {
    @content;
  }
}

// Large
@mixin desktopDown {
  @media only screen and (max-width: $screen-lg-minus1) {
    @content;
  }
}

// Large
@mixin desktopOnly {
  @media only screen and (min-width: $screen-lg) and (max-width: $screen-xl-minus1) {
    @content;
  }
}

// Large
@mixin desktop {
  @media only screen and (min-width: $screen-lg) {
    @content;
  }
}

// Large plus
@mixin largeDesktopDown {
  @media only screen and (max-width: $screen-xl-minus1) {
    @content;
  }
}

// Large plus
@mixin largeDesktop {
  @media only screen and (min-width: $screen-xl) {
    @content;
  }
}

// Large Plus
@mixin largeDesktopOnly {
  @media only screen and (min-width: $screen-xl) and (max-width: $screen-fhd-minus1) {
    @content;
  }
}

// Full hd
@mixin fhdDown {
  @media only screen and (max-width: $screen-fhd-minus1) {
    @content;
  }
}

// Full hd
@mixin fhd {
  @media only screen and (min-width: $screen-fhd) {
    @content;
  }
}

//== Custom media query
@mixin customQuery($customQuery) {
  @media only screen and ($customQuery) {
    @content;
  }
}

//== Print media query
@mixin print {
  @media print {
    @content;
  }
}