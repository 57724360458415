// Main footer
$_footer-space: 20px;

.o-footer {
  overflow: hidden;

  &__info {
    padding-bottom: $_footer-space * 2;

    @include laptop {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
    }

    &-left {
      flex-grow: 1;
      padding: $_footer-space * 2 0;

      body:not(.p-home) & {
      }

      body.p-home & {
        @include laptop {
          flex: 0 0 50%;
        }

        @include largeDesktop {
          flex: 0 0 60%;
        }
      }

      .p-eu .o-footer & {
        @include largeDesktop {
          display: flex;
        }
      }

      &__top {
        position: relative;
        padding-bottom: $_footer-space;
        margin-bottom: $_footer-space;

        @include largeDesktop {
          display: flex;
          align-items: flex-end;
        }

        .o-eu & {
          font-size: 1.375rem; //22px
        }

        .p-eu .o-footer & {
          @include largeDesktop {
            margin-right: $_footer-space * 3;
          }
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border-bottom: 1px solid $color-text-neutral;

          .p-eu .o-footer &,
          .o-eu & {
            content: normal;
          }

          @include laptop {
            width: 100%;
          }

          @include largeDesktop {
            width: 85%;
          }
        }

        .p-eu .o-footer &,
        .o-eu & {
          @include largeDesktop {
            display: block;
          }
        }

        img {
          height: 60px;

          @include largeDesktop {
            margin-left: $_footer-space * 2;
          }

          .p-eu .o-footer &,
          .o-eu & {
            margin-top: $_footer-space;

            @include largeDesktop {
              margin-left: 0;
            }
          }
        }
      }

      &__bot {
        .o-eu & {
          font-size: 1.375rem; //22px
        }

        .p-eu .o-footer & {
          max-width: 600px;
        }
      }

      &__flags {
        margin-top: $_footer-space;

        @include largeDesktop {
          display: flex;
          align-items: flex-end;
        }

        .p-eu .o-footer &,
        .o-eu & {
          @include largeDesktop {
            display: block;
          }
        }

        img {
          height: 50px;
          margin-bottom: $_footer-space;

          @include largeDesktop {
            margin-right: $_footer-space * 2;
            margin-bottom: 0;
          }

          .p-eu .o-footer & {
            margin-bottom: $_footer-space;
          }

          .o-eu & {
            height: 70px;
            margin-bottom: $_footer-space;
          }
        }
      }
    }

    &-right {
      @include webpbgimage("../media/app/internacionalni-sajam-prsuta");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      flex: 0 0 40%;
      min-height: 300px;
      margin-right: ($grid-gutter-width/2) * (-1);
      margin-left: ($grid-gutter-width/2) * (-1);

      body:not(.p-home) & {
        display: none;
      }

      @include laptop {
        min-height: 330px;
        background-position: right;
        transform: translateX($grid-gutter-width/2);
      }

      @include desktop {
        transform: translateX($grid-gutter-width-md);
      }

      @include largeDesktop {
        transform: translateX($grid-gutter-width-lg);
      }
    }
  }

  &__alt-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    &,
    > li {
      flex-grow: 1;
      @include laptop {
        height: 100%;
      }
    }

    > li {
      flex-basis: auto;
      padding: $_footer-space;

      @include laptop {
        padding: 0 $_footer-space;
      }

      @include desktop {
        padding: 0 $_footer-space * 3;
      }

      &:first-child {
        @include laptop {
          padding-left: 0;
        }
      }

      &:last-child {
        justify-content: center;
        @include laptop {
          justify-content: flex-end;
          padding-right: 0;
        }
      }

      &:nth-child(2) {
        @include laptop {
          border-right: 1px solid $brand-primary;
          border-left: 1px solid $brand-primary;
        }
      }
    }
  }

  &__logo {
    width: 130px;
    margin-top: $_footer-space/2;
    margin-bottom: $_footer-space;

    @include mobile {
      margin-top: 0;
      margin-right: $_footer-space * 2;
      margin-bottom: 0;
    }

    &.--alt {
      width: 160px;
      margin-right: 0;
    }
  }

  &__nav {
    li {
      margin: 5px 0;

      a {
        display: block;
        padding: 2px 0;
        font-size: 0.8125rem; //13px
      }
    }
  }

  &__facebook {
    font-size: 1.125rem; //18px
  }

  &__copy {
    padding: 30px 0 25px;

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin-top: 15px;

        @include tablet {
          margin-top: 0;
        }

        + li {
          padding-left: 10px;
          border-left: 1px solid $white;
          margin-left: 10px;

          @include tablet {
            padding-left: 5px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
