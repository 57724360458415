// Font Size
$body-font-size: 16px;


// Line height
$line-height-default: 1.2;
$line-height-md: 1.35;
$line-height-lg: 1.5;
$line-height-xl: 1.8;


// Letter spacing
$letter-spacing-default: 0.075rem;
$letter-spacing-lg: 0.02rem;


// Font Family
$font-family-sans-serif:      "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
$font-family-heading:         $font-family-sans-serif;
$font-family-serif:           "PT Serif", serif;


// Font Weight
$font-weight-black: 900;
$font-weight-heavy: 800;
$font-weight-heading: 700;
$font-weight-semiBold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 200;
