// Neutral colors
$white: #ffffff;
$black: #000000;

// Brand Primary
$brand-primary: #C00015;
$brand-primary-hover: lighten($brand-primary, 6%);

// Brand Secondary
$brand-secondary: $white;

// Text colors
$color-text-neutral: $black;
$color-text-light: #999999;

// Bg
$floral-white-bg: #FFFAF0;
$grey-light-bg: #F2F2F2;