/*------------------------------------------------------------------------------------
  00_settings
------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------
  Bootstrap includes
------------------------------------------------------------------------------------*/
/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1024px) {
  .container {
    max-width: 2080px;
  }
}

.container-fluid, .container-mdp {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1024px) {
  .container, .container-xs, .container-sm, .container-md, .container-mdp, .container-lg, .container-xl, .container-fhd {
    max-width: 2080px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-mdp-1, .col-mdp-2, .col-mdp-3, .col-mdp-4, .col-mdp-5, .col-mdp-6, .col-mdp-7, .col-mdp-8, .col-mdp-9, .col-mdp-10, .col-mdp-11, .col-mdp-12, .col-mdp,
.col-mdp-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-fhd-1, .col-fhd-2, .col-fhd-3, .col-fhd-4, .col-fhd-5, .col-fhd-6, .col-fhd-7, .col-fhd-8, .col-fhd-9, .col-fhd-10, .col-fhd-11, .col-fhd-12, .col-fhd,
.col-fhd-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 320px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xs-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xs-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 13;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333%;
  }
  .offset-xs-2 {
    margin-left: 16.66667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333%;
  }
  .offset-xs-5 {
    margin-left: 41.66667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333%;
  }
  .offset-xs-8 {
    margin-left: 66.66667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333%;
  }
  .offset-xs-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1024px) {
  .col-mdp {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-mdp-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-mdp-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-mdp-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-mdp-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-mdp-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-mdp-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-mdp-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-mdp-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-mdp-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-mdp-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-mdp-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-mdp-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-mdp-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-mdp-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-mdp-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-mdp-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-mdp-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-mdp-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-mdp-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-mdp-first {
    order: -1;
  }
  .order-mdp-last {
    order: 13;
  }
  .order-mdp-0 {
    order: 0;
  }
  .order-mdp-1 {
    order: 1;
  }
  .order-mdp-2 {
    order: 2;
  }
  .order-mdp-3 {
    order: 3;
  }
  .order-mdp-4 {
    order: 4;
  }
  .order-mdp-5 {
    order: 5;
  }
  .order-mdp-6 {
    order: 6;
  }
  .order-mdp-7 {
    order: 7;
  }
  .order-mdp-8 {
    order: 8;
  }
  .order-mdp-9 {
    order: 9;
  }
  .order-mdp-10 {
    order: 10;
  }
  .order-mdp-11 {
    order: 11;
  }
  .order-mdp-12 {
    order: 12;
  }
  .offset-mdp-0 {
    margin-left: 0;
  }
  .offset-mdp-1 {
    margin-left: 8.33333%;
  }
  .offset-mdp-2 {
    margin-left: 16.66667%;
  }
  .offset-mdp-3 {
    margin-left: 25%;
  }
  .offset-mdp-4 {
    margin-left: 33.33333%;
  }
  .offset-mdp-5 {
    margin-left: 41.66667%;
  }
  .offset-mdp-6 {
    margin-left: 50%;
  }
  .offset-mdp-7 {
    margin-left: 58.33333%;
  }
  .offset-mdp-8 {
    margin-left: 66.66667%;
  }
  .offset-mdp-9 {
    margin-left: 75%;
  }
  .offset-mdp-10 {
    margin-left: 83.33333%;
  }
  .offset-mdp-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1920px) {
  .col-fhd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-fhd-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-fhd-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-fhd-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-fhd-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-fhd-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-fhd-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-fhd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-fhd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-fhd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-fhd-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-fhd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-fhd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-fhd-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-fhd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-fhd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-fhd-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-fhd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-fhd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-fhd-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-fhd-first {
    order: -1;
  }
  .order-fhd-last {
    order: 13;
  }
  .order-fhd-0 {
    order: 0;
  }
  .order-fhd-1 {
    order: 1;
  }
  .order-fhd-2 {
    order: 2;
  }
  .order-fhd-3 {
    order: 3;
  }
  .order-fhd-4 {
    order: 4;
  }
  .order-fhd-5 {
    order: 5;
  }
  .order-fhd-6 {
    order: 6;
  }
  .order-fhd-7 {
    order: 7;
  }
  .order-fhd-8 {
    order: 8;
  }
  .order-fhd-9 {
    order: 9;
  }
  .order-fhd-10 {
    order: 10;
  }
  .order-fhd-11 {
    order: 11;
  }
  .order-fhd-12 {
    order: 12;
  }
  .offset-fhd-0 {
    margin-left: 0;
  }
  .offset-fhd-1 {
    margin-left: 8.33333%;
  }
  .offset-fhd-2 {
    margin-left: 16.66667%;
  }
  .offset-fhd-3 {
    margin-left: 25%;
  }
  .offset-fhd-4 {
    margin-left: 33.33333%;
  }
  .offset-fhd-5 {
    margin-left: 41.66667%;
  }
  .offset-fhd-6 {
    margin-left: 50%;
  }
  .offset-fhd-7 {
    margin-left: 58.33333%;
  }
  .offset-fhd-8 {
    margin-left: 66.66667%;
  }
  .offset-fhd-9 {
    margin-left: 75%;
  }
  .offset-fhd-10 {
    margin-left: 83.33333%;
  }
  .offset-fhd-11 {
    margin-left: 91.66667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 320px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .d-mdp-none {
    display: none !important;
  }
  .d-mdp-inline {
    display: inline !important;
  }
  .d-mdp-inline-block {
    display: inline-block !important;
  }
  .d-mdp-block {
    display: block !important;
  }
  .d-mdp-table {
    display: table !important;
  }
  .d-mdp-table-row {
    display: table-row !important;
  }
  .d-mdp-table-cell {
    display: table-cell !important;
  }
  .d-mdp-flex {
    display: flex !important;
  }
  .d-mdp-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1920px) {
  .d-fhd-none {
    display: none !important;
  }
  .d-fhd-inline {
    display: inline !important;
  }
  .d-fhd-inline-block {
    display: inline-block !important;
  }
  .d-fhd-block {
    display: block !important;
  }
  .d-fhd-table {
    display: table !important;
  }
  .d-fhd-table-row {
    display: table-row !important;
  }
  .d-fhd-table-cell {
    display: table-cell !important;
  }
  .d-fhd-flex {
    display: flex !important;
  }
  .d-fhd-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 320px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1024px) {
  .flex-mdp-row {
    flex-direction: row !important;
  }
  .flex-mdp-column {
    flex-direction: column !important;
  }
  .flex-mdp-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-mdp-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-mdp-wrap {
    flex-wrap: wrap !important;
  }
  .flex-mdp-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-mdp-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-mdp-fill {
    flex: 1 1 auto !important;
  }
  .flex-mdp-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-mdp-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-mdp-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-mdp-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-mdp-start {
    justify-content: flex-start !important;
  }
  .justify-content-mdp-end {
    justify-content: flex-end !important;
  }
  .justify-content-mdp-center {
    justify-content: center !important;
  }
  .justify-content-mdp-between {
    justify-content: space-between !important;
  }
  .justify-content-mdp-around {
    justify-content: space-around !important;
  }
  .align-items-mdp-start {
    align-items: flex-start !important;
  }
  .align-items-mdp-end {
    align-items: flex-end !important;
  }
  .align-items-mdp-center {
    align-items: center !important;
  }
  .align-items-mdp-baseline {
    align-items: baseline !important;
  }
  .align-items-mdp-stretch {
    align-items: stretch !important;
  }
  .align-content-mdp-start {
    align-content: flex-start !important;
  }
  .align-content-mdp-end {
    align-content: flex-end !important;
  }
  .align-content-mdp-center {
    align-content: center !important;
  }
  .align-content-mdp-between {
    align-content: space-between !important;
  }
  .align-content-mdp-around {
    align-content: space-around !important;
  }
  .align-content-mdp-stretch {
    align-content: stretch !important;
  }
  .align-self-mdp-auto {
    align-self: auto !important;
  }
  .align-self-mdp-start {
    align-self: flex-start !important;
  }
  .align-self-mdp-end {
    align-self: flex-end !important;
  }
  .align-self-mdp-center {
    align-self: center !important;
  }
  .align-self-mdp-baseline {
    align-self: baseline !important;
  }
  .align-self-mdp-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1280px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1920px) {
  .flex-fhd-row {
    flex-direction: row !important;
  }
  .flex-fhd-column {
    flex-direction: column !important;
  }
  .flex-fhd-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-fhd-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-fhd-wrap {
    flex-wrap: wrap !important;
  }
  .flex-fhd-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-fhd-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-fhd-fill {
    flex: 1 1 auto !important;
  }
  .flex-fhd-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-fhd-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-fhd-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-fhd-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-fhd-start {
    justify-content: flex-start !important;
  }
  .justify-content-fhd-end {
    justify-content: flex-end !important;
  }
  .justify-content-fhd-center {
    justify-content: center !important;
  }
  .justify-content-fhd-between {
    justify-content: space-between !important;
  }
  .justify-content-fhd-around {
    justify-content: space-around !important;
  }
  .align-items-fhd-start {
    align-items: flex-start !important;
  }
  .align-items-fhd-end {
    align-items: flex-end !important;
  }
  .align-items-fhd-center {
    align-items: center !important;
  }
  .align-items-fhd-baseline {
    align-items: baseline !important;
  }
  .align-items-fhd-stretch {
    align-items: stretch !important;
  }
  .align-content-fhd-start {
    align-content: flex-start !important;
  }
  .align-content-fhd-end {
    align-content: flex-end !important;
  }
  .align-content-fhd-center {
    align-content: center !important;
  }
  .align-content-fhd-between {
    align-content: space-between !important;
  }
  .align-content-fhd-around {
    align-content: space-around !important;
  }
  .align-content-fhd-stretch {
    align-content: stretch !important;
  }
  .align-self-fhd-auto {
    align-self: auto !important;
  }
  .align-self-fhd-start {
    align-self: flex-start !important;
  }
  .align-self-fhd-end {
    align-self: flex-end !important;
  }
  .align-self-fhd-center {
    align-self: center !important;
  }
  .align-self-fhd-baseline {
    align-self: baseline !important;
  }
  .align-self-fhd-stretch {
    align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 320px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.25rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1024px) {
  .m-mdp-0 {
    margin: 0 !important;
  }
  .mt-mdp-0,
  .my-mdp-0 {
    margin-top: 0 !important;
  }
  .mr-mdp-0,
  .mx-mdp-0 {
    margin-right: 0 !important;
  }
  .mb-mdp-0,
  .my-mdp-0 {
    margin-bottom: 0 !important;
  }
  .ml-mdp-0,
  .mx-mdp-0 {
    margin-left: 0 !important;
  }
  .m-mdp-1 {
    margin: 0.25rem !important;
  }
  .mt-mdp-1,
  .my-mdp-1 {
    margin-top: 0.25rem !important;
  }
  .mr-mdp-1,
  .mx-mdp-1 {
    margin-right: 0.25rem !important;
  }
  .mb-mdp-1,
  .my-mdp-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-mdp-1,
  .mx-mdp-1 {
    margin-left: 0.25rem !important;
  }
  .m-mdp-2 {
    margin: 0.5rem !important;
  }
  .mt-mdp-2,
  .my-mdp-2 {
    margin-top: 0.5rem !important;
  }
  .mr-mdp-2,
  .mx-mdp-2 {
    margin-right: 0.5rem !important;
  }
  .mb-mdp-2,
  .my-mdp-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-mdp-2,
  .mx-mdp-2 {
    margin-left: 0.5rem !important;
  }
  .m-mdp-3 {
    margin: 1rem !important;
  }
  .mt-mdp-3,
  .my-mdp-3 {
    margin-top: 1rem !important;
  }
  .mr-mdp-3,
  .mx-mdp-3 {
    margin-right: 1rem !important;
  }
  .mb-mdp-3,
  .my-mdp-3 {
    margin-bottom: 1rem !important;
  }
  .ml-mdp-3,
  .mx-mdp-3 {
    margin-left: 1rem !important;
  }
  .m-mdp-4 {
    margin: 1.5rem !important;
  }
  .mt-mdp-4,
  .my-mdp-4 {
    margin-top: 1.5rem !important;
  }
  .mr-mdp-4,
  .mx-mdp-4 {
    margin-right: 1.5rem !important;
  }
  .mb-mdp-4,
  .my-mdp-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-mdp-4,
  .mx-mdp-4 {
    margin-left: 1.5rem !important;
  }
  .m-mdp-5 {
    margin: 3rem !important;
  }
  .mt-mdp-5,
  .my-mdp-5 {
    margin-top: 3rem !important;
  }
  .mr-mdp-5,
  .mx-mdp-5 {
    margin-right: 3rem !important;
  }
  .mb-mdp-5,
  .my-mdp-5 {
    margin-bottom: 3rem !important;
  }
  .ml-mdp-5,
  .mx-mdp-5 {
    margin-left: 3rem !important;
  }
  .p-mdp-0 {
    padding: 0 !important;
  }
  .pt-mdp-0,
  .py-mdp-0 {
    padding-top: 0 !important;
  }
  .pr-mdp-0,
  .px-mdp-0 {
    padding-right: 0 !important;
  }
  .pb-mdp-0,
  .py-mdp-0 {
    padding-bottom: 0 !important;
  }
  .pl-mdp-0,
  .px-mdp-0 {
    padding-left: 0 !important;
  }
  .p-mdp-1 {
    padding: 0.25rem !important;
  }
  .pt-mdp-1,
  .py-mdp-1 {
    padding-top: 0.25rem !important;
  }
  .pr-mdp-1,
  .px-mdp-1 {
    padding-right: 0.25rem !important;
  }
  .pb-mdp-1,
  .py-mdp-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-mdp-1,
  .px-mdp-1 {
    padding-left: 0.25rem !important;
  }
  .p-mdp-2 {
    padding: 0.5rem !important;
  }
  .pt-mdp-2,
  .py-mdp-2 {
    padding-top: 0.5rem !important;
  }
  .pr-mdp-2,
  .px-mdp-2 {
    padding-right: 0.5rem !important;
  }
  .pb-mdp-2,
  .py-mdp-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-mdp-2,
  .px-mdp-2 {
    padding-left: 0.5rem !important;
  }
  .p-mdp-3 {
    padding: 1rem !important;
  }
  .pt-mdp-3,
  .py-mdp-3 {
    padding-top: 1rem !important;
  }
  .pr-mdp-3,
  .px-mdp-3 {
    padding-right: 1rem !important;
  }
  .pb-mdp-3,
  .py-mdp-3 {
    padding-bottom: 1rem !important;
  }
  .pl-mdp-3,
  .px-mdp-3 {
    padding-left: 1rem !important;
  }
  .p-mdp-4 {
    padding: 1.5rem !important;
  }
  .pt-mdp-4,
  .py-mdp-4 {
    padding-top: 1.5rem !important;
  }
  .pr-mdp-4,
  .px-mdp-4 {
    padding-right: 1.5rem !important;
  }
  .pb-mdp-4,
  .py-mdp-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-mdp-4,
  .px-mdp-4 {
    padding-left: 1.5rem !important;
  }
  .p-mdp-5 {
    padding: 3rem !important;
  }
  .pt-mdp-5,
  .py-mdp-5 {
    padding-top: 3rem !important;
  }
  .pr-mdp-5,
  .px-mdp-5 {
    padding-right: 3rem !important;
  }
  .pb-mdp-5,
  .py-mdp-5 {
    padding-bottom: 3rem !important;
  }
  .pl-mdp-5,
  .px-mdp-5 {
    padding-left: 3rem !important;
  }
  .m-mdp-n1 {
    margin: -0.25rem !important;
  }
  .mt-mdp-n1,
  .my-mdp-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-mdp-n1,
  .mx-mdp-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-mdp-n1,
  .my-mdp-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-mdp-n1,
  .mx-mdp-n1 {
    margin-left: -0.25rem !important;
  }
  .m-mdp-n2 {
    margin: -0.5rem !important;
  }
  .mt-mdp-n2,
  .my-mdp-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-mdp-n2,
  .mx-mdp-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-mdp-n2,
  .my-mdp-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-mdp-n2,
  .mx-mdp-n2 {
    margin-left: -0.5rem !important;
  }
  .m-mdp-n3 {
    margin: -1rem !important;
  }
  .mt-mdp-n3,
  .my-mdp-n3 {
    margin-top: -1rem !important;
  }
  .mr-mdp-n3,
  .mx-mdp-n3 {
    margin-right: -1rem !important;
  }
  .mb-mdp-n3,
  .my-mdp-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-mdp-n3,
  .mx-mdp-n3 {
    margin-left: -1rem !important;
  }
  .m-mdp-n4 {
    margin: -1.5rem !important;
  }
  .mt-mdp-n4,
  .my-mdp-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-mdp-n4,
  .mx-mdp-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-mdp-n4,
  .my-mdp-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-mdp-n4,
  .mx-mdp-n4 {
    margin-left: -1.5rem !important;
  }
  .m-mdp-n5 {
    margin: -3rem !important;
  }
  .mt-mdp-n5,
  .my-mdp-n5 {
    margin-top: -3rem !important;
  }
  .mr-mdp-n5,
  .mx-mdp-n5 {
    margin-right: -3rem !important;
  }
  .mb-mdp-n5,
  .my-mdp-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-mdp-n5,
  .mx-mdp-n5 {
    margin-left: -3rem !important;
  }
  .m-mdp-auto {
    margin: auto !important;
  }
  .mt-mdp-auto,
  .my-mdp-auto {
    margin-top: auto !important;
  }
  .mr-mdp-auto,
  .mx-mdp-auto {
    margin-right: auto !important;
  }
  .mb-mdp-auto,
  .my-mdp-auto {
    margin-bottom: auto !important;
  }
  .ml-mdp-auto,
  .mx-mdp-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1920px) {
  .m-fhd-0 {
    margin: 0 !important;
  }
  .mt-fhd-0,
  .my-fhd-0 {
    margin-top: 0 !important;
  }
  .mr-fhd-0,
  .mx-fhd-0 {
    margin-right: 0 !important;
  }
  .mb-fhd-0,
  .my-fhd-0 {
    margin-bottom: 0 !important;
  }
  .ml-fhd-0,
  .mx-fhd-0 {
    margin-left: 0 !important;
  }
  .m-fhd-1 {
    margin: 0.25rem !important;
  }
  .mt-fhd-1,
  .my-fhd-1 {
    margin-top: 0.25rem !important;
  }
  .mr-fhd-1,
  .mx-fhd-1 {
    margin-right: 0.25rem !important;
  }
  .mb-fhd-1,
  .my-fhd-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-fhd-1,
  .mx-fhd-1 {
    margin-left: 0.25rem !important;
  }
  .m-fhd-2 {
    margin: 0.5rem !important;
  }
  .mt-fhd-2,
  .my-fhd-2 {
    margin-top: 0.5rem !important;
  }
  .mr-fhd-2,
  .mx-fhd-2 {
    margin-right: 0.5rem !important;
  }
  .mb-fhd-2,
  .my-fhd-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-fhd-2,
  .mx-fhd-2 {
    margin-left: 0.5rem !important;
  }
  .m-fhd-3 {
    margin: 1rem !important;
  }
  .mt-fhd-3,
  .my-fhd-3 {
    margin-top: 1rem !important;
  }
  .mr-fhd-3,
  .mx-fhd-3 {
    margin-right: 1rem !important;
  }
  .mb-fhd-3,
  .my-fhd-3 {
    margin-bottom: 1rem !important;
  }
  .ml-fhd-3,
  .mx-fhd-3 {
    margin-left: 1rem !important;
  }
  .m-fhd-4 {
    margin: 1.5rem !important;
  }
  .mt-fhd-4,
  .my-fhd-4 {
    margin-top: 1.5rem !important;
  }
  .mr-fhd-4,
  .mx-fhd-4 {
    margin-right: 1.5rem !important;
  }
  .mb-fhd-4,
  .my-fhd-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-fhd-4,
  .mx-fhd-4 {
    margin-left: 1.5rem !important;
  }
  .m-fhd-5 {
    margin: 3rem !important;
  }
  .mt-fhd-5,
  .my-fhd-5 {
    margin-top: 3rem !important;
  }
  .mr-fhd-5,
  .mx-fhd-5 {
    margin-right: 3rem !important;
  }
  .mb-fhd-5,
  .my-fhd-5 {
    margin-bottom: 3rem !important;
  }
  .ml-fhd-5,
  .mx-fhd-5 {
    margin-left: 3rem !important;
  }
  .p-fhd-0 {
    padding: 0 !important;
  }
  .pt-fhd-0,
  .py-fhd-0 {
    padding-top: 0 !important;
  }
  .pr-fhd-0,
  .px-fhd-0 {
    padding-right: 0 !important;
  }
  .pb-fhd-0,
  .py-fhd-0 {
    padding-bottom: 0 !important;
  }
  .pl-fhd-0,
  .px-fhd-0 {
    padding-left: 0 !important;
  }
  .p-fhd-1 {
    padding: 0.25rem !important;
  }
  .pt-fhd-1,
  .py-fhd-1 {
    padding-top: 0.25rem !important;
  }
  .pr-fhd-1,
  .px-fhd-1 {
    padding-right: 0.25rem !important;
  }
  .pb-fhd-1,
  .py-fhd-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-fhd-1,
  .px-fhd-1 {
    padding-left: 0.25rem !important;
  }
  .p-fhd-2 {
    padding: 0.5rem !important;
  }
  .pt-fhd-2,
  .py-fhd-2 {
    padding-top: 0.5rem !important;
  }
  .pr-fhd-2,
  .px-fhd-2 {
    padding-right: 0.5rem !important;
  }
  .pb-fhd-2,
  .py-fhd-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-fhd-2,
  .px-fhd-2 {
    padding-left: 0.5rem !important;
  }
  .p-fhd-3 {
    padding: 1rem !important;
  }
  .pt-fhd-3,
  .py-fhd-3 {
    padding-top: 1rem !important;
  }
  .pr-fhd-3,
  .px-fhd-3 {
    padding-right: 1rem !important;
  }
  .pb-fhd-3,
  .py-fhd-3 {
    padding-bottom: 1rem !important;
  }
  .pl-fhd-3,
  .px-fhd-3 {
    padding-left: 1rem !important;
  }
  .p-fhd-4 {
    padding: 1.5rem !important;
  }
  .pt-fhd-4,
  .py-fhd-4 {
    padding-top: 1.5rem !important;
  }
  .pr-fhd-4,
  .px-fhd-4 {
    padding-right: 1.5rem !important;
  }
  .pb-fhd-4,
  .py-fhd-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-fhd-4,
  .px-fhd-4 {
    padding-left: 1.5rem !important;
  }
  .p-fhd-5 {
    padding: 3rem !important;
  }
  .pt-fhd-5,
  .py-fhd-5 {
    padding-top: 3rem !important;
  }
  .pr-fhd-5,
  .px-fhd-5 {
    padding-right: 3rem !important;
  }
  .pb-fhd-5,
  .py-fhd-5 {
    padding-bottom: 3rem !important;
  }
  .pl-fhd-5,
  .px-fhd-5 {
    padding-left: 3rem !important;
  }
  .m-fhd-n1 {
    margin: -0.25rem !important;
  }
  .mt-fhd-n1,
  .my-fhd-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-fhd-n1,
  .mx-fhd-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-fhd-n1,
  .my-fhd-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-fhd-n1,
  .mx-fhd-n1 {
    margin-left: -0.25rem !important;
  }
  .m-fhd-n2 {
    margin: -0.5rem !important;
  }
  .mt-fhd-n2,
  .my-fhd-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-fhd-n2,
  .mx-fhd-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-fhd-n2,
  .my-fhd-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-fhd-n2,
  .mx-fhd-n2 {
    margin-left: -0.5rem !important;
  }
  .m-fhd-n3 {
    margin: -1rem !important;
  }
  .mt-fhd-n3,
  .my-fhd-n3 {
    margin-top: -1rem !important;
  }
  .mr-fhd-n3,
  .mx-fhd-n3 {
    margin-right: -1rem !important;
  }
  .mb-fhd-n3,
  .my-fhd-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-fhd-n3,
  .mx-fhd-n3 {
    margin-left: -1rem !important;
  }
  .m-fhd-n4 {
    margin: -1.5rem !important;
  }
  .mt-fhd-n4,
  .my-fhd-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-fhd-n4,
  .mx-fhd-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-fhd-n4,
  .my-fhd-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-fhd-n4,
  .mx-fhd-n4 {
    margin-left: -1.5rem !important;
  }
  .m-fhd-n5 {
    margin: -3rem !important;
  }
  .mt-fhd-n5,
  .my-fhd-n5 {
    margin-top: -3rem !important;
  }
  .mr-fhd-n5,
  .mx-fhd-n5 {
    margin-right: -3rem !important;
  }
  .mb-fhd-n5,
  .my-fhd-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-fhd-n5,
  .mx-fhd-n5 {
    margin-left: -3rem !important;
  }
  .m-fhd-auto {
    margin: auto !important;
  }
  .mt-fhd-auto,
  .my-fhd-auto {
    margin-top: auto !important;
  }
  .mr-fhd-auto,
  .mx-fhd-auto {
    margin-right: auto !important;
  }
  .mb-fhd-auto,
  .my-fhd-auto {
    margin-bottom: auto !important;
  }
  .ml-fhd-auto,
  .mx-fhd-auto {
    margin-left: auto !important;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 320px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .d-mdp-none {
    display: none !important;
  }
  .d-mdp-inline {
    display: inline !important;
  }
  .d-mdp-inline-block {
    display: inline-block !important;
  }
  .d-mdp-block {
    display: block !important;
  }
  .d-mdp-table {
    display: table !important;
  }
  .d-mdp-table-row {
    display: table-row !important;
  }
  .d-mdp-table-cell {
    display: table-cell !important;
  }
  .d-mdp-flex {
    display: flex !important;
  }
  .d-mdp-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1920px) {
  .d-fhd-none {
    display: none !important;
  }
  .d-fhd-inline {
    display: inline !important;
  }
  .d-fhd-inline-block {
    display: inline-block !important;
  }
  .d-fhd-block {
    display: block !important;
  }
  .d-fhd-table {
    display: table !important;
  }
  .d-fhd-table-row {
    display: table-row !important;
  }
  .d-fhd-table-cell {
    display: table-cell !important;
  }
  .d-fhd-flex {
    display: flex !important;
  }
  .d-fhd-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 320px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.25rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1024px) {
  .m-mdp-0 {
    margin: 0 !important;
  }
  .mt-mdp-0,
  .my-mdp-0 {
    margin-top: 0 !important;
  }
  .mr-mdp-0,
  .mx-mdp-0 {
    margin-right: 0 !important;
  }
  .mb-mdp-0,
  .my-mdp-0 {
    margin-bottom: 0 !important;
  }
  .ml-mdp-0,
  .mx-mdp-0 {
    margin-left: 0 !important;
  }
  .m-mdp-1 {
    margin: 0.25rem !important;
  }
  .mt-mdp-1,
  .my-mdp-1 {
    margin-top: 0.25rem !important;
  }
  .mr-mdp-1,
  .mx-mdp-1 {
    margin-right: 0.25rem !important;
  }
  .mb-mdp-1,
  .my-mdp-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-mdp-1,
  .mx-mdp-1 {
    margin-left: 0.25rem !important;
  }
  .m-mdp-2 {
    margin: 0.5rem !important;
  }
  .mt-mdp-2,
  .my-mdp-2 {
    margin-top: 0.5rem !important;
  }
  .mr-mdp-2,
  .mx-mdp-2 {
    margin-right: 0.5rem !important;
  }
  .mb-mdp-2,
  .my-mdp-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-mdp-2,
  .mx-mdp-2 {
    margin-left: 0.5rem !important;
  }
  .m-mdp-3 {
    margin: 1rem !important;
  }
  .mt-mdp-3,
  .my-mdp-3 {
    margin-top: 1rem !important;
  }
  .mr-mdp-3,
  .mx-mdp-3 {
    margin-right: 1rem !important;
  }
  .mb-mdp-3,
  .my-mdp-3 {
    margin-bottom: 1rem !important;
  }
  .ml-mdp-3,
  .mx-mdp-3 {
    margin-left: 1rem !important;
  }
  .m-mdp-4 {
    margin: 1.5rem !important;
  }
  .mt-mdp-4,
  .my-mdp-4 {
    margin-top: 1.5rem !important;
  }
  .mr-mdp-4,
  .mx-mdp-4 {
    margin-right: 1.5rem !important;
  }
  .mb-mdp-4,
  .my-mdp-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-mdp-4,
  .mx-mdp-4 {
    margin-left: 1.5rem !important;
  }
  .m-mdp-5 {
    margin: 3rem !important;
  }
  .mt-mdp-5,
  .my-mdp-5 {
    margin-top: 3rem !important;
  }
  .mr-mdp-5,
  .mx-mdp-5 {
    margin-right: 3rem !important;
  }
  .mb-mdp-5,
  .my-mdp-5 {
    margin-bottom: 3rem !important;
  }
  .ml-mdp-5,
  .mx-mdp-5 {
    margin-left: 3rem !important;
  }
  .p-mdp-0 {
    padding: 0 !important;
  }
  .pt-mdp-0,
  .py-mdp-0 {
    padding-top: 0 !important;
  }
  .pr-mdp-0,
  .px-mdp-0 {
    padding-right: 0 !important;
  }
  .pb-mdp-0,
  .py-mdp-0 {
    padding-bottom: 0 !important;
  }
  .pl-mdp-0,
  .px-mdp-0 {
    padding-left: 0 !important;
  }
  .p-mdp-1 {
    padding: 0.25rem !important;
  }
  .pt-mdp-1,
  .py-mdp-1 {
    padding-top: 0.25rem !important;
  }
  .pr-mdp-1,
  .px-mdp-1 {
    padding-right: 0.25rem !important;
  }
  .pb-mdp-1,
  .py-mdp-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-mdp-1,
  .px-mdp-1 {
    padding-left: 0.25rem !important;
  }
  .p-mdp-2 {
    padding: 0.5rem !important;
  }
  .pt-mdp-2,
  .py-mdp-2 {
    padding-top: 0.5rem !important;
  }
  .pr-mdp-2,
  .px-mdp-2 {
    padding-right: 0.5rem !important;
  }
  .pb-mdp-2,
  .py-mdp-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-mdp-2,
  .px-mdp-2 {
    padding-left: 0.5rem !important;
  }
  .p-mdp-3 {
    padding: 1rem !important;
  }
  .pt-mdp-3,
  .py-mdp-3 {
    padding-top: 1rem !important;
  }
  .pr-mdp-3,
  .px-mdp-3 {
    padding-right: 1rem !important;
  }
  .pb-mdp-3,
  .py-mdp-3 {
    padding-bottom: 1rem !important;
  }
  .pl-mdp-3,
  .px-mdp-3 {
    padding-left: 1rem !important;
  }
  .p-mdp-4 {
    padding: 1.5rem !important;
  }
  .pt-mdp-4,
  .py-mdp-4 {
    padding-top: 1.5rem !important;
  }
  .pr-mdp-4,
  .px-mdp-4 {
    padding-right: 1.5rem !important;
  }
  .pb-mdp-4,
  .py-mdp-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-mdp-4,
  .px-mdp-4 {
    padding-left: 1.5rem !important;
  }
  .p-mdp-5 {
    padding: 3rem !important;
  }
  .pt-mdp-5,
  .py-mdp-5 {
    padding-top: 3rem !important;
  }
  .pr-mdp-5,
  .px-mdp-5 {
    padding-right: 3rem !important;
  }
  .pb-mdp-5,
  .py-mdp-5 {
    padding-bottom: 3rem !important;
  }
  .pl-mdp-5,
  .px-mdp-5 {
    padding-left: 3rem !important;
  }
  .m-mdp-n1 {
    margin: -0.25rem !important;
  }
  .mt-mdp-n1,
  .my-mdp-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-mdp-n1,
  .mx-mdp-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-mdp-n1,
  .my-mdp-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-mdp-n1,
  .mx-mdp-n1 {
    margin-left: -0.25rem !important;
  }
  .m-mdp-n2 {
    margin: -0.5rem !important;
  }
  .mt-mdp-n2,
  .my-mdp-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-mdp-n2,
  .mx-mdp-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-mdp-n2,
  .my-mdp-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-mdp-n2,
  .mx-mdp-n2 {
    margin-left: -0.5rem !important;
  }
  .m-mdp-n3 {
    margin: -1rem !important;
  }
  .mt-mdp-n3,
  .my-mdp-n3 {
    margin-top: -1rem !important;
  }
  .mr-mdp-n3,
  .mx-mdp-n3 {
    margin-right: -1rem !important;
  }
  .mb-mdp-n3,
  .my-mdp-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-mdp-n3,
  .mx-mdp-n3 {
    margin-left: -1rem !important;
  }
  .m-mdp-n4 {
    margin: -1.5rem !important;
  }
  .mt-mdp-n4,
  .my-mdp-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-mdp-n4,
  .mx-mdp-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-mdp-n4,
  .my-mdp-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-mdp-n4,
  .mx-mdp-n4 {
    margin-left: -1.5rem !important;
  }
  .m-mdp-n5 {
    margin: -3rem !important;
  }
  .mt-mdp-n5,
  .my-mdp-n5 {
    margin-top: -3rem !important;
  }
  .mr-mdp-n5,
  .mx-mdp-n5 {
    margin-right: -3rem !important;
  }
  .mb-mdp-n5,
  .my-mdp-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-mdp-n5,
  .mx-mdp-n5 {
    margin-left: -3rem !important;
  }
  .m-mdp-auto {
    margin: auto !important;
  }
  .mt-mdp-auto,
  .my-mdp-auto {
    margin-top: auto !important;
  }
  .mr-mdp-auto,
  .mx-mdp-auto {
    margin-right: auto !important;
  }
  .mb-mdp-auto,
  .my-mdp-auto {
    margin-bottom: auto !important;
  }
  .ml-mdp-auto,
  .mx-mdp-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1920px) {
  .m-fhd-0 {
    margin: 0 !important;
  }
  .mt-fhd-0,
  .my-fhd-0 {
    margin-top: 0 !important;
  }
  .mr-fhd-0,
  .mx-fhd-0 {
    margin-right: 0 !important;
  }
  .mb-fhd-0,
  .my-fhd-0 {
    margin-bottom: 0 !important;
  }
  .ml-fhd-0,
  .mx-fhd-0 {
    margin-left: 0 !important;
  }
  .m-fhd-1 {
    margin: 0.25rem !important;
  }
  .mt-fhd-1,
  .my-fhd-1 {
    margin-top: 0.25rem !important;
  }
  .mr-fhd-1,
  .mx-fhd-1 {
    margin-right: 0.25rem !important;
  }
  .mb-fhd-1,
  .my-fhd-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-fhd-1,
  .mx-fhd-1 {
    margin-left: 0.25rem !important;
  }
  .m-fhd-2 {
    margin: 0.5rem !important;
  }
  .mt-fhd-2,
  .my-fhd-2 {
    margin-top: 0.5rem !important;
  }
  .mr-fhd-2,
  .mx-fhd-2 {
    margin-right: 0.5rem !important;
  }
  .mb-fhd-2,
  .my-fhd-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-fhd-2,
  .mx-fhd-2 {
    margin-left: 0.5rem !important;
  }
  .m-fhd-3 {
    margin: 1rem !important;
  }
  .mt-fhd-3,
  .my-fhd-3 {
    margin-top: 1rem !important;
  }
  .mr-fhd-3,
  .mx-fhd-3 {
    margin-right: 1rem !important;
  }
  .mb-fhd-3,
  .my-fhd-3 {
    margin-bottom: 1rem !important;
  }
  .ml-fhd-3,
  .mx-fhd-3 {
    margin-left: 1rem !important;
  }
  .m-fhd-4 {
    margin: 1.5rem !important;
  }
  .mt-fhd-4,
  .my-fhd-4 {
    margin-top: 1.5rem !important;
  }
  .mr-fhd-4,
  .mx-fhd-4 {
    margin-right: 1.5rem !important;
  }
  .mb-fhd-4,
  .my-fhd-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-fhd-4,
  .mx-fhd-4 {
    margin-left: 1.5rem !important;
  }
  .m-fhd-5 {
    margin: 3rem !important;
  }
  .mt-fhd-5,
  .my-fhd-5 {
    margin-top: 3rem !important;
  }
  .mr-fhd-5,
  .mx-fhd-5 {
    margin-right: 3rem !important;
  }
  .mb-fhd-5,
  .my-fhd-5 {
    margin-bottom: 3rem !important;
  }
  .ml-fhd-5,
  .mx-fhd-5 {
    margin-left: 3rem !important;
  }
  .p-fhd-0 {
    padding: 0 !important;
  }
  .pt-fhd-0,
  .py-fhd-0 {
    padding-top: 0 !important;
  }
  .pr-fhd-0,
  .px-fhd-0 {
    padding-right: 0 !important;
  }
  .pb-fhd-0,
  .py-fhd-0 {
    padding-bottom: 0 !important;
  }
  .pl-fhd-0,
  .px-fhd-0 {
    padding-left: 0 !important;
  }
  .p-fhd-1 {
    padding: 0.25rem !important;
  }
  .pt-fhd-1,
  .py-fhd-1 {
    padding-top: 0.25rem !important;
  }
  .pr-fhd-1,
  .px-fhd-1 {
    padding-right: 0.25rem !important;
  }
  .pb-fhd-1,
  .py-fhd-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-fhd-1,
  .px-fhd-1 {
    padding-left: 0.25rem !important;
  }
  .p-fhd-2 {
    padding: 0.5rem !important;
  }
  .pt-fhd-2,
  .py-fhd-2 {
    padding-top: 0.5rem !important;
  }
  .pr-fhd-2,
  .px-fhd-2 {
    padding-right: 0.5rem !important;
  }
  .pb-fhd-2,
  .py-fhd-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-fhd-2,
  .px-fhd-2 {
    padding-left: 0.5rem !important;
  }
  .p-fhd-3 {
    padding: 1rem !important;
  }
  .pt-fhd-3,
  .py-fhd-3 {
    padding-top: 1rem !important;
  }
  .pr-fhd-3,
  .px-fhd-3 {
    padding-right: 1rem !important;
  }
  .pb-fhd-3,
  .py-fhd-3 {
    padding-bottom: 1rem !important;
  }
  .pl-fhd-3,
  .px-fhd-3 {
    padding-left: 1rem !important;
  }
  .p-fhd-4 {
    padding: 1.5rem !important;
  }
  .pt-fhd-4,
  .py-fhd-4 {
    padding-top: 1.5rem !important;
  }
  .pr-fhd-4,
  .px-fhd-4 {
    padding-right: 1.5rem !important;
  }
  .pb-fhd-4,
  .py-fhd-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-fhd-4,
  .px-fhd-4 {
    padding-left: 1.5rem !important;
  }
  .p-fhd-5 {
    padding: 3rem !important;
  }
  .pt-fhd-5,
  .py-fhd-5 {
    padding-top: 3rem !important;
  }
  .pr-fhd-5,
  .px-fhd-5 {
    padding-right: 3rem !important;
  }
  .pb-fhd-5,
  .py-fhd-5 {
    padding-bottom: 3rem !important;
  }
  .pl-fhd-5,
  .px-fhd-5 {
    padding-left: 3rem !important;
  }
  .m-fhd-n1 {
    margin: -0.25rem !important;
  }
  .mt-fhd-n1,
  .my-fhd-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-fhd-n1,
  .mx-fhd-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-fhd-n1,
  .my-fhd-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-fhd-n1,
  .mx-fhd-n1 {
    margin-left: -0.25rem !important;
  }
  .m-fhd-n2 {
    margin: -0.5rem !important;
  }
  .mt-fhd-n2,
  .my-fhd-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-fhd-n2,
  .mx-fhd-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-fhd-n2,
  .my-fhd-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-fhd-n2,
  .mx-fhd-n2 {
    margin-left: -0.5rem !important;
  }
  .m-fhd-n3 {
    margin: -1rem !important;
  }
  .mt-fhd-n3,
  .my-fhd-n3 {
    margin-top: -1rem !important;
  }
  .mr-fhd-n3,
  .mx-fhd-n3 {
    margin-right: -1rem !important;
  }
  .mb-fhd-n3,
  .my-fhd-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-fhd-n3,
  .mx-fhd-n3 {
    margin-left: -1rem !important;
  }
  .m-fhd-n4 {
    margin: -1.5rem !important;
  }
  .mt-fhd-n4,
  .my-fhd-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-fhd-n4,
  .mx-fhd-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-fhd-n4,
  .my-fhd-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-fhd-n4,
  .mx-fhd-n4 {
    margin-left: -1.5rem !important;
  }
  .m-fhd-n5 {
    margin: -3rem !important;
  }
  .mt-fhd-n5,
  .my-fhd-n5 {
    margin-top: -3rem !important;
  }
  .mr-fhd-n5,
  .mx-fhd-n5 {
    margin-right: -3rem !important;
  }
  .mb-fhd-n5,
  .my-fhd-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-fhd-n5,
  .mx-fhd-n5 {
    margin-left: -3rem !important;
  }
  .m-fhd-auto {
    margin: auto !important;
  }
  .mt-fhd-auto,
  .my-fhd-auto {
    margin-top: auto !important;
  }
  .mr-fhd-auto,
  .mx-fhd-auto {
    margin-right: auto !important;
  }
  .mb-fhd-auto,
  .my-fhd-auto {
    margin-bottom: auto !important;
  }
  .ml-fhd-auto,
  .mx-fhd-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 320px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1024px) {
  .text-mdp-left {
    text-align: left !important;
  }
  .text-mdp-right {
    text-align: right !important;
  }
  .text-mdp-center {
    text-align: center !important;
  }
}

@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1920px) {
  .text-fhd-left {
    text-align: left !important;
  }
  .text-fhd-right {
    text-align: right !important;
  }
  .text-fhd-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

/*------------------------------------------------------------------------------------
  01_tools
------------------------------------------------------------------------------------*/
.c-link:not(.--btn):hover, .c-link.--underlined {
  text-decoration: underline;
}

a, .c-link.--underlined:hover {
  text-decoration: none;
}

/*------------------------------------------------------------------------------------
  08_vendor
------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------
  02_generic
------------------------------------------------------------------------------------*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.075rem;
  color: #000000;
}

@media only screen and (min-width: 1280px) {
  html .container, body .container {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media only screen and (min-width: 1600px) {
  html .container, body .container {
    padding-right: 80px;
    padding-left: 80px;
  }
}

html .container-from-left, body .container-from-left {
  padding-left: 20px;
}

@media only screen and (min-width: 1280px) {
  html .container-from-left, body .container-from-left {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 1600px) {
  html .container-from-left, body .container-from-left {
    padding-left: 80px;
  }
}

/*------------------------------------------------------------------------------------
  03_base
------------------------------------------------------------------------------------*/
a {
  color: inherit;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
}

hr.hr-double {
  border: none;
  border-bottom: 2px solid #000000;
}

select, option {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select {
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/*------------------------------------------------------------------------------------
  04_components
------------------------------------------------------------------------------------*/
.c-link.--btn {
  transition: background-color 0.3s ease;
  display: inline-block;
  padding: 5px 15px;
  background-color: #C00015;
  color: #ffffff;
  font-size: .875em;
}

@media (prefers-reduced-motion: reduce) {
  .c-link.--btn {
    transition: none;
  }
}

.c-link.--btn:hover {
  background-color: #df0018;
}

.o-footer__facebook .c-link.--btn {
  border-radius: 6px;
}

body .c-preformat h1, body .c-preformat h2, body .c-preformat h3, body .c-preformat h4, body .c-preformat h5, body .c-preformat h6 {
  margin-bottom: 1rem;
  font-weight: normal;
}

body .c-preformat h1 {
  font-size: 2rem;
}

body .c-preformat h2 {
  font-size: 1.875rem;
}

body .c-preformat h3 {
  font-size: 1.75rem;
}

body .c-preformat h4 {
  font-size: 1.625rem;
}

body .c-preformat h5 {
  font-size: 1.5rem;
}

body .c-preformat h6 {
  font-size: 1.375rem;
}

body .c-preformat blockquote {
  margin: 0 -50px 2rem -30px;
  padding: 30px 50px;
  background-color: #C00015;
  color: #ffffff;
}

body .c-preformat ul {
  line-height: 2.5;
  list-style-type: disc;
}

body .c-preformat p {
  margin-top: 0;
  margin-bottom: 2rem;
}

body .c-preformat img {
  width: 100%;
  margin-bottom: 2rem;
}

body .c-preformat img.img-sm {
  max-width: 180px;
}

body .c-preformat img.img-md {
  max-width: 480px;
}

.c-ttl.--lg {
  font-size: 2.25em;
}

@media only screen and (min-width: 576px) {
  .c-ttl.--lg {
    font-size: 2.625em;
  }
}

@media only screen and (min-width: 1024px) {
  .c-ttl.--lg {
    font-size: 3.125em;
  }
}

@media only screen and (min-width: 1920px) {
  .c-ttl.--lg {
    font-size: 3em;
  }
}

.o-map__left .c-ttl.--lg {
  max-width: 400px;
}

.c-ttl.--xl {
  font-size: 2.5em;
}

@media only screen and (min-width: 576px) {
  .c-ttl.--xl {
    font-size: 3em;
  }
}

@media only screen and (min-width: 1024px) {
  .c-ttl.--xl {
    font-size: 3.5em;
  }
}

@media only screen and (min-width: 1920px) {
  .c-ttl.--xl {
    font-size: 4.5em;
  }
}

.c-txt {
  font-size: 1.25em;
  font-family: "PT Serif", serif;
}

.c-txt.--sm {
  font-size: 1em;
}

@media only screen and (min-width: 1024px) {
  .c-txt.--sm {
    font-size: 1.125em;
  }
}

@media only screen and (min-width: 1920px) {
  .c-txt.--sm {
    font-size: 1.125em;
  }
}

.c-txt.--mdp {
  font-size: 1.125em;
}

@media only screen and (min-width: 1024px) {
  .c-txt.--mdp {
    font-size: 1.25em;
  }
}

@media only screen and (min-width: 1920px) {
  .c-txt.--mdp {
    font-size: 1.375em;
  }
}

.c-txt.--lg {
  font-size: 1.25em;
}

@media only screen and (min-width: 1024px) {
  .c-txt.--lg {
    font-size: 1.375em;
  }
}

@media only screen and (min-width: 1920px) {
  .c-txt.--lg {
    font-size: 1.5em;
  }
}

/*------------------------------------------------------------------------------------
  05_objects
------------------------------------------------------------------------------------*/
.o-footer {
  overflow: hidden;
}

.o-footer__info {
  padding-bottom: 40px;
}

@media only screen and (min-width: 1024px) {
  .o-footer__info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.o-footer__info-left {
  flex-grow: 1;
  padding: 40px 0;
}

@media only screen and (min-width: 1024px) {
  body.p-home .o-footer__info-left {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 1600px) {
  body.p-home .o-footer__info-left {
    flex: 0 0 60%;
  }
}

@media only screen and (min-width: 1600px) {
  .p-eu .o-footer .o-footer__info-left {
    display: flex;
  }
}

.o-footer__info-left__top {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1600px) {
  .o-footer__info-left__top {
    display: flex;
    align-items: flex-end;
  }
}

.o-eu .o-footer__info-left__top {
  font-size: 1.375rem;
}

@media only screen and (min-width: 1600px) {
  .p-eu .o-footer .o-footer__info-left__top {
    margin-right: 60px;
  }
}

.o-footer__info-left__top:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #000000;
}

.p-eu .o-footer .o-footer__info-left__top:before,
.o-eu .o-footer__info-left__top:before {
  content: normal;
}

@media only screen and (min-width: 1024px) {
  .o-footer__info-left__top:before {
    width: 100%;
  }
}

@media only screen and (min-width: 1600px) {
  .o-footer__info-left__top:before {
    width: 85%;
  }
}

@media only screen and (min-width: 1600px) {
  .p-eu .o-footer .o-footer__info-left__top,
  .o-eu .o-footer__info-left__top {
    display: block;
  }
}

.o-footer__info-left__top img {
  height: 60px;
}

@media only screen and (min-width: 1600px) {
  .o-footer__info-left__top img {
    margin-left: 40px;
  }
}

.p-eu .o-footer .o-footer__info-left__top img,
.o-eu .o-footer__info-left__top img {
  margin-top: 20px;
}

@media only screen and (min-width: 1600px) {
  .p-eu .o-footer .o-footer__info-left__top img,
  .o-eu .o-footer__info-left__top img {
    margin-left: 0;
  }
}

.o-eu .o-footer__info-left__bot {
  font-size: 1.375rem;
}

.p-eu .o-footer .o-footer__info-left__bot {
  max-width: 600px;
}

.o-footer__info-left__flags {
  margin-top: 20px;
}

@media only screen and (min-width: 1600px) {
  .o-footer__info-left__flags {
    display: flex;
    align-items: flex-end;
  }
}

@media only screen and (min-width: 1600px) {
  .p-eu .o-footer .o-footer__info-left__flags,
  .o-eu .o-footer__info-left__flags {
    display: block;
  }
}

.o-footer__info-left__flags img {
  height: 50px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1600px) {
  .o-footer__info-left__flags img {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.p-eu .o-footer .o-footer__info-left__flags img {
  margin-bottom: 20px;
}

.o-eu .o-footer__info-left__flags img {
  height: 70px;
  margin-bottom: 20px;
}

.o-footer__info-right {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex: 0 0 40%;
  min-height: 300px;
  margin-right: -20px;
  margin-left: -20px;
}

.webp .o-footer__info-right {
  background-image: url("../media/app/internacionalni-sajam-prsuta.webp");
}

.no-webp .o-footer__info-right {
  background-image: url("../media/app/internacionalni-sajam-prsuta.png");
}

body:not(.p-home) .o-footer__info-right {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .o-footer__info-right {
    min-height: 330px;
    background-position: right;
    transform: translateX(20px);
  }
}

@media only screen and (min-width: 1280px) {
  .o-footer__info-right {
    transform: translateX(60px);
  }
}

@media only screen and (min-width: 1600px) {
  .o-footer__info-right {
    transform: translateX(80px);
  }
}

.o-footer__alt-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .o-footer__alt-info {
    flex-direction: row;
  }
}

.o-footer__alt-info,
.o-footer__alt-info > li {
  flex-grow: 1;
}

@media only screen and (min-width: 1024px) {
  .o-footer__alt-info,
  .o-footer__alt-info > li {
    height: 100%;
  }
}

.o-footer__alt-info > li {
  flex-basis: auto;
  padding: 20px;
}

@media only screen and (min-width: 1024px) {
  .o-footer__alt-info > li {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .o-footer__alt-info > li {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-footer__alt-info > li:first-child {
    padding-left: 0;
  }
}

.o-footer__alt-info > li:last-child {
  justify-content: center;
}

@media only screen and (min-width: 1024px) {
  .o-footer__alt-info > li:last-child {
    justify-content: flex-end;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .o-footer__alt-info > li:nth-child(2) {
    border-right: 1px solid #C00015;
    border-left: 1px solid #C00015;
  }
}

.o-footer__logo {
  width: 130px;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 576px) {
  .o-footer__logo {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.o-footer__logo.--alt {
  width: 160px;
  margin-right: 0;
}

.o-footer__nav li {
  margin: 5px 0;
}

.o-footer__nav li a {
  display: block;
  padding: 2px 0;
  font-size: 0.8125rem;
}

.o-footer__facebook {
  font-size: 1.125rem;
}

.o-footer__copy {
  padding: 30px 0 25px;
}

.o-footer__copy ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.o-footer__copy ul li {
  margin-top: 15px;
}

@media only screen and (min-width: 768px) {
  .o-footer__copy ul li {
    margin-top: 0;
  }
}

.o-footer__copy ul li + li {
  padding-left: 10px;
  border-left: 1px solid #ffffff;
  margin-left: 10px;
}

@media only screen and (min-width: 768px) {
  .o-footer__copy ul li + li {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.o-header {
  position: relative;
  z-index: 9;
}

.p-home .o-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  color: #ffffff;
}

.p-producers-list .o-header {
  margin-bottom: 80px;
}

@media only screen and (min-width: 576px) {
  .p-producers-list .o-header {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 1024px) {
  .p-producers-list .o-header {
    margin-bottom: 0;
  }
}

.o-header__content {
  position: relative;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  body:not(.p-home) .o-header__content {
    padding: 20px 0 30px 230px;
  }
}

.o-intro-banner {
  display: flex;
  align-items: flex-end;
  padding-top: 200px;
  padding-bottom: 0;
  margin-top: -1px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.p-home .o-intro-banner {
  padding-bottom: 100px;
  min-height: 800px;
}

.p-about .o-intro-banner {
  background-position: center 70%;
}

@media only screen and (min-width: 576px) {
  .o-intro-banner {
    padding-top: 250px;
  }
}

.o-intro-banner.no-desc {
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 576px) {
  .o-intro-banner.no-desc {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .o-intro-banner {
    height: 630px;
  }
  .p-home .o-intro-banner {
    height: 90vh;
  }
}

.o-lang {
  display: flex;
  align-items: center;
}

.o-lang li:not(:first-child) a {
  position: relative;
}

.o-lang li:not(:first-child) a:before {
  content: '';
  height: 1em;
  width: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #ffffff;
}

body:not(.p-home) .o-lang li:not(:first-child) a:before {
  background-color: #000000;
}

.o-lang li a {
  padding: 10px;
  font-weight: bold;
}

.o-lang li.active a {
  color: #C00015;
}

@media only screen and (min-width: 576px) {
  .o-logo {
    margin-right: 50px;
  }
}

body:not(.p-home) .o-logo {
  position: absolute;
  top: 0;
  left: 0;
}

.o-logo img {
  width: 120px;
}

@media only screen and (min-width: 576px) {
  .o-logo img {
    width: 181px;
  }
}

.o-map {
  overflow: hidden;
  max-width: 1920px;
  margin: auto;
}

.o-map__outer {
  background-color: #FFFAF0;
}

@media only screen and (min-width: 1024px) {
  .o-map {
    display: flex;
    justify-content: space-between;
  }
}

.o-map__right {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

@media only screen and (min-width: 1024px) {
  .o-map__right {
    margin-top: 0;
  }
}

.o-map__left {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

@media only screen and (max-width: 1023px) {
  .o-map__left {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-map__left {
    padding-left: 60px;
  }
}

.o-map__wrapper, .o-map__map {
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .o-map__wrapper, .o-map__map {
    width: 460px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-map__wrapper, .o-map__map {
    width: 726px;
  }
}

.o-map__map {
  display: block;
}

.o-map__wrapper {
  position: relative;
}

.o-markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-markers button {
  position: absolute;
  width: 4%;
  aspect-ratio: 1;
  padding: 0;
  border-radius: 100%;
  background-color: #C00015;
  color: #fff;
  font-family: "PT Serif", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 10px;
}

@media only screen and (min-width: 576px) {
  .o-markers button {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-markers button {
    width: 32px;
    height: 32px;
    font-size: 18px;
  }
}

.o-markers .marker-8 {
  left: 32%;
  top: 23.5%;
}

.o-markers .marker-11 {
  left: 36.75%;
  top: 23.5%;
}

.o-markers .marker-4 {
  left: 31%;
  top: 30.5%;
}

.o-markers .marker-10 {
  left: 52%;
  top: 33%;
}

.o-markers .marker-1 {
  left: 36.5%;
  top: 37%;
}

.o-markers .marker-5 {
  left: 41.25%;
  top: 37%;
}

.o-markers .marker-9 {
  left: 46%;
  top: 37%;
}

.o-markers .marker-12 {
  left: 26%;
  top: 40.5%;
}

.o-markers .marker-6 {
  left: 42%;
  top: 44.5%;
}

.o-markers .marker-7 {
  left: 46.75%;
  top: 44.5%;
}

.o-markers .marker-2 {
  left: 42%;
  top: 66%;
}

.o-markers .marker-3 {
  left: 37.25%;
  top: 44.5%;
}

.o-map-drop {
  position: absolute;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  margin-top: 20px;
}

.o-map-drop:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #C00015;
  border-width: 16px;
  margin-left: -16px;
}

.o-map-drop.active {
  opacity: 1;
  pointer-events: all;
}

.o-map-drop ul {
  padding: 20px 20px 50px;
  background-color: #C00015;
  border-radius: 12px;
}

@media only screen and (min-width: 576px) {
  .o-map-drop ul {
    padding: 20px 100px 20px 20px;
  }
}

.o-map-drop ul li {
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 0.8125rem;
}

.o-map-drop .arrow {
  position: absolute;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
}

@media only screen and (min-width: 576px) {
  .o-map-drop .arrow {
    bottom: 20px;
    right: 20px;
    transform: translateX(0);
  }
}

.o-map-drop .arrow img {
  width: 31px;
}

.o-mobile-nav-trigger {
  position: absolute;
  right: 0;
  bottom: -40px;
  padding-left: 40px;
  transform: translateY(-50%);
}

body:not(.p-home) .o-mobile-nav-trigger {
  bottom: -85px;
}

@media only screen and (min-width: 576px) {
  body:not(.p-home) .o-mobile-nav-trigger {
    bottom: -40px;
  }
}

@media only screen and (min-width: 576px) {
  .o-mobile-nav-trigger {
    top: 100px;
    bottom: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .o-mobile-nav-trigger {
    display: none;
  }
}

.o-mobile-nav-trigger__wrapper {
  width: 40px;
  height: 30px;
  position: relative;
  margin: 10px auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.o-mobile-nav-trigger__wrapper span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: #ffffff;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.p-eu .o-mobile-nav-trigger__wrapper span,
.p-producers-list .o-mobile-nav-trigger__wrapper span {
  background: #000000;
}

.o-mobile-nav-trigger__wrapper.active span {
  background: rgba(0, 0, 0, 0.8);
}

.o-mobile-nav-trigger__wrapper span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.o-mobile-nav-trigger__wrapper span:nth-child(odd) {
  left: 0;
  border-radius: 9px 0 0 9px;
}

.o-mobile-nav-trigger__wrapper span:nth-child(1),
.o-mobile-nav-trigger__wrapper span:nth-child(2) {
  top: 0;
}

.o-mobile-nav-trigger__wrapper span:nth-child(3),
.o-mobile-nav-trigger__wrapper span:nth-child(4) {
  top: 12px;
}

.o-mobile-nav-trigger__wrapper span:nth-child(5),
.o-mobile-nav-trigger__wrapper span:nth-child(6) {
  top: 24px;
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(1),
.o-mobile-nav-trigger__wrapper.active span:nth-child(6) {
  transform: rotate(45deg);
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(2),
.o-mobile-nav-trigger__wrapper.active span:nth-child(5) {
  transform: rotate(-45deg);
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(1) {
  left: 5px;
  top: 9px;
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(2) {
  left: calc(50% - 5px);
  top: 9px;
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(5) {
  left: 5px;
  top: 20px;
}

.o-mobile-nav-trigger__wrapper.active span:nth-child(6) {
  left: calc(50% - 5px);
  top: 20px;
}

.o-nav {
  flex-grow: 1;
}

@media only screen and (min-width: 1024px) {
  body:not(.p-home) .o-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media only screen and (min-width: 1600px) {
  body:not(.p-home) .o-nav {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

.o-nav__sub {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 60px;
}

body:not(.p-home) .o-nav__sub {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  body:not(.p-home) .o-nav__sub {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1600px) {
  body:not(.p-home) .o-nav__sub {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 576px) {
  .o-nav__sub {
    flex-direction: row;
    align-items: center;
  }
}

.o-nav__main {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .o-nav__main {
    position: absolute;
    left: -20px;
    right: -20px;
    top: 0;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.97);
  }
}

@media only screen and (min-width: 1024px) {
  .o-nav__main {
    display: block;
  }
}

.o-nav__main.active {
  display: block;
}

.o-nav__main-x {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
}

.o-nav__main-x:before, .o-nav__main-x:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: #ffffff;
}

.o-nav__main-x:before {
  transform: rotate(45deg) translate(-10px, -10px);
}

.o-nav__main-x:after {
  transform: rotate(-45deg) translate(10px, -10px);
}

@media only screen and (min-width: 1024px) {
  .o-nav__main-x {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .o-nav__main > ul {
    padding: 40px 20px 2000px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-nav__main > ul {
    display: flex;
    align-items: center;
  }
}

.o-nav__main > ul > li {
  position: relative;
}

.o-nav__main > ul > li:not(:last-child) {
  margin-right: 15px;
}

@media only screen and (max-width: 1023px) {
  .o-nav__main > ul > li:not(:last-child) {
    margin-right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.o-nav__main > ul > li a {
  display: block;
  text-transform: uppercase;
  font-size: .8125em;
  color: #ffffff;
  font-weight: bold;
}

.o-nav__main > ul > li > a {
  position: relative;
}

@media only screen and (min-width: 1024px) {
  body:not(.p-home) .o-nav__main > ul > li > a {
    color: #000000;
  }
}

@media only screen and (max-width: 1023px) {
  .o-nav__main > ul > li > a {
    padding: 10px 0;
  }
}

.o-nav__main > ul > li > a:hover:after {
  width: 100%;
}

.o-nav__main > ul > li:hover > a {
  color: #C00015 !important;
}

@media only screen and (min-width: 1024px) {
  .o-nav__main > ul > li:hover .sub-nav {
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
  }
}

.o-nav__main > ul > li .mobile-trigger {
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  font-weight: normal;
  font-size: 2em;
  height: 35px;
  width: 50px;
  text-align: right;
  z-index: 5;
}

.o-nav__main > ul > li .mobile-trigger > span {
  display: inline-block;
  transform: rotate(0);
  transition: transform 0.3s ease;
}

@media (prefers-reduced-motion: reduce) {
  .o-nav__main > ul > li .mobile-trigger > span {
    transition: none;
  }
}

@media only screen and (min-width: 576px) {
  .o-nav__main > ul > li .mobile-trigger {
    width: 100px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-nav__main > ul > li .mobile-trigger {
    display: none;
  }
}

.o-nav__main > ul > li .mobile-trigger-input:checked ~ .sub-nav {
  display: block;
}

.o-nav__main > ul > li .mobile-trigger-input:checked ~ .mobile-trigger > span {
  transform: rotate(45deg);
}

.o-nav__main > ul > li > .sub-nav {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .o-nav__main > ul > li > .sub-nav {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    padding-top: 20px;
    transform: translate(0, 20px);
    opacity: 0;
    pointer-events: none;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}

@media only screen and (min-width: 1024px) and (prefers-reduced-motion: reduce) {
  .o-nav__main > ul > li > .sub-nav {
    transition: none;
  }
}

.o-nav__main > ul > li > .sub-nav > ul {
  position: relative;
  padding: 10px;
  background-color: #C00015;
}

@media only screen and (min-width: 1024px) {
  .o-nav__main > ul > li > .sub-nav > ul {
    padding: 20px;
    border-radius: 12px;
    white-space: nowrap;
  }
}

.o-nav__main > ul > li > .sub-nav > ul:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #C00015;
  border-width: 10px;
  margin-left: -10px;
}

@media only screen and (min-width: 1024px) {
  .o-nav__main > ul > li > .sub-nav > ul:before {
    border-width: 16px;
    margin-left: -16px;
  }
}

.o-nav__main > ul > li > .sub-nav > ul li:not(:last-child) a {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.o-nav__main > ul > li > .sub-nav > ul a {
  padding: 5px;
}

@media only screen and (min-width: 1024px) {
  .o-nav__main > ul > li > .sub-nav > ul a {
    padding: 10px 5px;
  }
}

.o-nav__main > ul > li > .sub-nav > ul a:hover {
  color: #ffcad0;
}

.o-producer-list {
  display: flex;
  flex-wrap: wrap;
}

.o-producer-list li {
  flex: 1 1 100%;
  padding-right: 20px;
  font-family: "PT Serif", serif;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .o-producer-list li {
    flex: 1 1 50%;
  }
}

@media only screen and (min-width: 1280px) {
  .o-producer-list li {
    flex: 1 1 50%;
  }
}

@media only screen and (min-width: 1600px) {
  .o-producer-list li {
    padding-right: 50px;
  }
}

.o-producer-list li button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #C00015;
  font-size: 1em;
}

@media only screen and (min-width: 1600px) {
  .o-producer-list li button {
    font-size: 1.25em;
  }
}

.o-producer-list li button.active {
  background-color: #C00015;
}

.o-producer-list li button.active,
.o-producer-list li button.active .number {
  color: #ffffff;
}

.o-producer-list li button.active .icon {
  opacity: 1;
}

.o-producer-list li .number,
.o-producer-list li .icon {
  display: inline-block;
}

.o-producer-list li .number {
  margin-right: 10px;
  color: #C00015;
}

.o-producer-list li .icon {
  display: inline-block;
  width: 20px;
  margin-left: 10px;
  opacity: 0;
}

@media only screen and (min-width: 1600px) {
  .o-producer-list li .icon {
    width: 31px;
    margin-left: 20px;
  }
}

.o-social {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (min-width: 576px) {
  .o-social {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.o-social img {
  width: 35px;
}

@media only screen and (min-width: 576px) {
  .o-well.--inner {
    margin: 0 50px;
  }
}

@media only screen and (min-width: 768px) {
  .o-well.--inner {
    margin: 0 76.66667px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-well.--inner {
    margin: 0 230px;
  }
}

.o-stackedBlocks {
  overflow: hidden;
}

.o-stackedBlocks .container:nth-child(even) {
  direction: ltr;
}

.o-stackedBlocks__logo {
  width: 140px;
}

.o-home-other-products {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.webp .o-home-other-products {
  background-image: url("../media/app/home/home-other-products.webp");
}

.no-webp .o-home-other-products {
  background-image: url("../media/app/home/home-other-products.png");
}

@media only screen and (min-width: 768px) {
  .o-home-other-products {
    height: 690px;
  }
}

.o-home-other-products__content {
  padding: 160px 20px;
}

@media only screen and (min-width: 768px) {
  .o-home-other-products__content {
    padding: 0 40px;
  }
}

.o-home-other-products__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 576px) {
  .o-home-other-products__list {
    flex-direction: row;
  }
}

.o-home-other-products__list li {
  margin: 20px;
}

.o-home-other-products__list li img {
  width: 80px;
}

.o-home-trademark {
  background-color: #FFFAF0;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .o-home-trademark__inner {
    display: flex;
    flex-direction: row;
  }
}

.o-home-trademark__content {
  display: flex;
  justify-content: center;
  padding: 80px 0 100px;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .o-home-trademark__content {
    padding: 120px 120px 120px 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-home-trademark__content {
    flex: 0 0 60%;
  }
}

.o-home-trademark__image {
  position: relative;
  aspect-ratio: 1.33;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.webp .o-home-trademark__image {
  background-image: url("../media/app/home/home-trademark-bg.webp");
}

.no-webp .o-home-trademark__image {
  background-image: url("../media/app/home/home-trademark-bg.png");
}

@media only screen and (min-width: 576px) {
  .o-home-trademark__image {
    height: 600px;
  }
}

@media only screen and (max-width: 1023px) {
  .o-home-trademark__image {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-home-trademark__image {
    flex-grow: 1;
    height: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .o-home-trademark__image {
    margin-right: -60px;
  }
}

@media only screen and (min-width: 1600px) {
  .o-home-trademark__image {
    margin-right: -80px;
  }
}

.o-home-trademark__logo {
  position: absolute;
  top: 0;
  right: 50%;
  width: 140px;
  transform: translate(50%, -50%);
}

@media only screen and (min-width: 768px) {
  .o-home-trademark__logo {
    width: 180px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-home-trademark__logo {
    top: 50%;
    right: 100%;
  }
}

.o-stackedBlocks .o-home-trademark__inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

@media only screen and (min-width: 1024px) {
  .o-stackedBlocks .o-home-trademark__inner {
    flex-wrap: nowrap;
  }
}

.o-stackedBlocks .o-home-trademark__content, .o-stackedBlocks .o-home-trademark__image {
  flex: 0 0 100%;
  margin: 0;
}

@media only screen and (min-width: 1024px) {
  .o-stackedBlocks .o-home-trademark__content, .o-stackedBlocks .o-home-trademark__image {
    flex: 1 0 50%;
    order: 0;
  }
}

.o-stackedBlocks .o-home-trademark__content {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 20px 0;
  order: 1;
}

@media only screen and (min-width: 1024px) {
  .o-stackedBlocks .o-home-trademark__content {
    flex: 0 0 50%;
    order: 0;
    max-width: 100%;
  }
}

.p-producers-list .o-stackedBlocks .o-home-trademark__content {
  line-height: 1.4;
}

.o-stackedBlocks .o-home-trademark__image {
  aspect-ratio: 1.33;
}

@media only screen and (min-width: 1024px) {
  .o-stackedBlocks .o-home-trademark__image {
    height: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .o-stackedBlocks > .container:nth-child(odd) .o-home-trademark__content {
    padding: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .o-stackedBlocks > .container:nth-child(odd) .o-home-trademark__content {
    padding: 40px;
  }
}

@media only screen and (min-width: 1600px) {
  .o-stackedBlocks > .container:nth-child(odd) .o-home-trademark__content {
    padding: 80px;
  }
}

@media only screen and (min-width: 1280px) {
  .o-stackedBlocks > .container:nth-child(odd) .o-home-trademark__image {
    margin-right: -40px;
  }
}

@media only screen and (min-width: 1600px) {
  .o-stackedBlocks > .container:nth-child(odd) .o-home-trademark__image {
    margin-right: -60px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-stackedBlocks > .container:nth-child(even) .o-home-trademark__inner {
    direction: rtl;
  }
}

@media only screen and (min-width: 1280px) {
  .o-stackedBlocks > .container:nth-child(even) .o-home-trademark__image {
    margin-right: 0;
    margin-left: -40px;
  }
}

@media only screen and (min-width: 1600px) {
  .o-stackedBlocks > .container:nth-child(even) .o-home-trademark__image {
    margin-left: -60px;
  }
}

@media only screen and (min-width: 1024px) {
  .o-stackedBlocks > .container:nth-child(even) .o-home-trademark__content {
    text-align: left;
    direction: ltr;
  }
}

@media only screen and (min-width: 1280px) {
  .o-stackedBlocks > .container:nth-child(even) .o-home-trademark__content {
    padding: 80px;
  }
}

/*------------------------------------------------------------------------------------
  06_pages
------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------
  07_trumps
------------------------------------------------------------------------------------*/
/*
  Helper classes for background color
*/
.bg-grey-light {
  background-color: #F2F2F2;
}

.bg-primary {
  background-color: #C00015;
}

/*
  Helper classes for font families
*/
.ff-base {
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ff-serif {
  font-family: "PT Serif", serif;
}

/*
  Helper classes for font weights
*/
.fw-normal {
  font-weight: normal;
}

/*
  Helper classes for max-width
*/
.mw-500 {
  max-width: 500px;
}

.mw-670 {
  max-width: 670px;
}

.mw-800 {
  max-width: 800px;
}

/*
  Helper classes for text color
*/
.txt-light {
  color: #999999;
}

.txt-white {
  color: #ffffff;
}

.txt-primary {
  color: #C00015;
}
