.o-lang {
  display: flex;
  align-items: center;
  
  li {
    
    &:not(:first-child) {
      
      a {
        position: relative;
        
        &:before {
          content: '';
          height: 1em;
          width: 1px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: $white;

          body:not(.p-home) & {
            background-color: $color-text-neutral;
          }
        }
      }
      
    }
    
    a {
      padding: 10px;
      font-weight: bold;
    }
    
    &.active {
      a {
        color: $brand-primary;
      }
    }
  }
}
