// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$screen-zero: 0;
$screen-xs-minus1: 319px;
$screen-xs: 320px;
$screen-sm-minus1: 575px;
$screen-sm: 576px;
$screen-md-minus1: 767px;
$screen-md: 768px;
$screen-mdp-minus1: 1023px;
$screen-mdp: 1024px;
$screen-lg-minus1: 1279px;
$screen-lg: 1280px;
$screen-xl-minus1: 1599px;
$screen-xl: 1600px;
$screen-fhd-minus1: 1919px;
$screen-fhd: 1920px;

$grid-breakpoints: (
  zr: $screen-zero,
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  mdp: $screen-mdp,
  lg: $screen-lg,
  xl: $screen-xl,
  fhd: $screen-fhd
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           40px;
$grid-gutter-width-md:        $grid-gutter-width*1.5;
$grid-gutter-width-lg:        $grid-gutter-width*2;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths-mdp: 1920px + $grid-gutter-width*4;

$container-max-widths: (
  mdp: $container-max-widths-mdp
);
