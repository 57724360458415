.o-producer-list {
  display: flex;
  flex-wrap: wrap;
  
  li {
    flex: 1 1 100%;
    padding-right: 20px;
    font-family: $font-family-serif;
    
    @include tabletOnly {
      flex: 1 1 50%;
    }
    
    @include desktop {
      flex: 1 1 50%;
    }

    @include largeDesktop {
      padding-right: 50px;
    }
    
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid $brand-primary;
      font-size: 1em; //16px
      
      @include largeDesktop {
        font-size: 1.25em; //20px
      }

      &.active {
        background-color: $brand-primary;
        
        &,
        .number {
          color: $white;
        }

        .icon {
          opacity: 1;
        }
      }
    }
    
    .number,
    .icon {
      display: inline-block;
    }
    
    .number {
      margin-right: 10px;
      color: $brand-primary;
    }
    
    .icon {
      display: inline-block;
      width: 20px;
      margin-left: 10px;
      opacity: 0;

      @include largeDesktop {
        width: 31px;
        margin-left: 20px;
      }
    }
  }
}
