/*
  Helper classes for max-width
*/

.mw-200 {
  //max-width: 200px;
}

.mw-220 {
  //max-width: 220px;
}

.mw-260 {
  //max-width: 260px;
}

.mw-350 {
  //max-width: 350px;
}

.mw-450 {
  //max-width: 450px;
}

.mw-500 {
  max-width: 500px;
}

.mw-540 {
  //max-width: 540px;
}

.mw-600 {
  //max-width: 600px;
}

.mw-630 {
  //max-width: 630px;
}

.mw-670 {
  max-width: 670px;
}

.mw-780 {
  //max-width: 780px;
}

.mw-800 {
  max-width: 800px;
}

.mw-900 {
  //max-width: 900px;
}

.mw-950 {
  //max-width: 950px;
}

.mw-1050 {
  //max-width: 1050px;
}

.mw-container-max {
  //max-width: $container-max-widths-mdp;
}
