.o-stackedBlocks {
  overflow: hidden;

  .container:nth-child(even) {
    direction: ltr;
  }

  &__logo {
    width: 140px;
  }
}