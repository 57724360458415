.o-map {
  overflow: hidden;
  max-width: $screen-fhd;
  margin: auto;

  &__outer {
    background-color: $floral-white-bg;
  }

  @include laptop {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @include laptop {
      margin-top: 0;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    flex-grow: 1;

    @include laptopDown {
      padding-top: 60px;
    }

    @include laptop {
      padding-left: 60px;
    }
  }

  &__wrapper,
  &__map {
    width: 100%;

    @include mobile {
      width: 460px;
    }

    @include laptop {
      width: 726px;
    }
  }

  &__map {
    display: block;
  }

  &__wrapper {
    position: relative;
  }
}

.o-markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  button {
    position: absolute;
    width: 4%;
    aspect-ratio: 1;
    padding: 0;
    border-radius: 100%;
    background-color: $brand-primary;
    color: #fff;
    font-family: $font-family-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 10px;

    @include mobile {
      width: 18px;
      height: 18px;
      font-size: 12px;
    }

    @include laptop {
      width: 32px;
      height: 32px;
      font-size: 18px;
    }
  }

  .marker-8 {
    left: 32%;
    top: 23.5%;
  }

  .marker-11 {
    left: 36.75%;
    top: 23.5%;
  }

  .marker-4 {
    left: 31%;
    top: 30.5%;
  }

  .marker-10 {
    left: 52%;
    top: 33%;
  }

  .marker-1 {
    left: 36.5%;
    top: 37%;
  }

  .marker-5 {
    left: 41.25%;
    top: 37%;
  }

  .marker-9 {
    left: 46%;
    top: 37%;
  }

  .marker-12 {
    left: 26%;
    top: 40.5%;
  }

  .marker-6 {
    left: 42%;
    top: 44.5%;
  }

  .marker-7 {
    left: 46.75%;
    top: 44.5%;
  }

  .marker-2 {
    left: 42%;
    top: 66%;
  }

  .marker-3 {
    left: 37.25%;
    top: 44.5%;
  }
}

.o-map-drop {
  position: absolute;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  margin-top: 20px;

  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $brand-primary;
    border-width: 16px;
    margin-left: -16px;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  ul {
    padding: 20px 20px 50px;
    background-color: $brand-primary;
    border-radius: 12px;

    @include mobile {
      padding: 20px 100px 20px 20px;
    }

    li {
      margin-bottom: 5px;
      color: $white;
      font-size: 0.8125rem; //13px

      a {
      }
    }
  }

  .arrow {
    position: absolute;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);

    @include mobile {
      bottom: 20px;
      right: 20px;
      transform: translateX(0);
    }

    img {
      width: 31px;
    }
  }
}
