.o-well {
  
  &.--inner {

    @include mobile {
      margin: 0 50px;
    }

    @include tablet {
      margin: 0 $inner-content-space/3;
    }

    @include laptop {
      margin: 0 $inner-content-space;
    }
  }
}
