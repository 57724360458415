body .c-preformat {
  h1 ,h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    font-weight: normal;
  }
  
  h1 {
    font-size: 2rem; //32px
  }

  h2 {
    font-size: 1.875rem; //30px
  }

  h3 {
    font-size: 1.75rem; //28px
  }

  h4 {
    font-size: 1.625rem; //26px
  }

  h5 {
    font-size: 1.5rem; //24px
  }

  h6 {
    font-size: 1.375rem; //22px
  }

  blockquote {
    margin: 0 -50px 2rem -30px;
    padding: 30px 50px;
    background-color: $brand-primary;
    color: $white;
  }

  ul {
    line-height: 2.5;
    list-style-type: disc;
  }

  p {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
    margin-bottom: 2rem;
    
    &.img-sm {
      max-width: 180px;
    }

    &.img-md {
      max-width: 480px;
    }
  }
}
