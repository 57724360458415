.o-home-other-products {
  @include webpbgimage('../media/app/home/home-other-products');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  @include tablet {
    height: 690px;
  }
  
  &__content {
    padding: 160px 20px;

    @include tablet {
      padding: 0 40px;
    }
  }
  
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    @include mobile{
      flex-direction: row;
    }
    
    li {
      margin: 20px;
      
      img {
        width: 80px;
      }
    }
  }
}
