// Mobile navigation trigger

.o-mobile-nav-trigger {
  position: absolute;
  right: 0;
  bottom: -40px;
  padding-left: 40px;
  transform: translateY(-50%);

  body:not(.p-home) & {
    bottom: -85px;
    
    @include mobile {
      bottom: -40px;
    }
  }
  
  @include mobile {
    top: 100px;
    bottom: auto;
  }
  
  @include laptop {
    display: none;
  }

  &__wrapper {
    width: 40px;
    height: 30px;
    position: relative;
    margin: 10px auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;


    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 50%;
      background: $white;
      opacity: 1;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      .p-eu &,
      .p-producers-list & {
        background: $black;
      }
    }

    &.active span {
      background: rgba($black, .8);
    }

    span:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }

    span:nth-child(odd) {
      left:0;
      border-radius: 9px 0 0 9px;
    }

    span:nth-child(1), 
    span:nth-child(2) {
      top: 0;
    }

    span:nth-child(3),
    span:nth-child(4) {
      top: 12px;
    }

    span:nth-child(5), 
    span:nth-child(6) {
      top: 24px;
    }

    &.active span:nth-child(1),
    &.active span:nth-child(6) {
      transform: rotate(45deg);
    }

    &.active span:nth-child(2),
    &.active span:nth-child(5) {
      transform: rotate(-45deg);
    }

    &.active span:nth-child(1) {
      left: 5px;
      top: 9px;
    }

    &.active span:nth-child(2) {
      left: calc(50% - 5px);
      top: 9px;
    }

    &.active span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }

    &.active span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }

    &.active span:nth-child(5) {
      left: 5px;
      top: 20px;
    }

    &.active span:nth-child(6) {
      left: calc(50% - 5px);
      top: 20px;
    }
  }
}
