// Logo
.o-logo {
  
  @include mobile {
    margin-right: 50px;
  }

  body:not(.p-home) & {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  img {
    width: 120px;
    
    @include mobile {
      width: 181px;
    }
  }
}
