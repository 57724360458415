.o-social {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
  @include mobile {
    margin-right: 30px;
    margin-bottom: 0;
  }
  
  img {
    width: 35px;
  }
}
