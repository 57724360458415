// Titles
.c-ttl {

  &.--sm {}

  &.--md {}
  
  &.--lg {
    font-size: 2.25em; //36px
    
    @include mobile {
      font-size: 2.625em; //42px
    }

    @include laptop {
      font-size: 3.125em; //50px
    }

    @include largeDesktop {}

    @include fhd {
      font-size: 3em; //48px
    }
    
    .o-map__left & {
      max-width: 400px;
    }
  }
  
  &.--xl {
    font-size: 2.5em; //40px

    @include mobile {
      font-size: 3em; //48px
    }

    @include laptop {
      font-size: 3.5em; //56px
    }

    @include largeDesktop {}
    
    @include fhd {
      font-size: 4.5em; //72px
    }
  }
}
