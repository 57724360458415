/*
  Helper classes for text color
*/

.txt-light {
  color: $color-text-light;
}

.txt-white {
  color: $white;
}

.txt-primary {
  color: $brand-primary;
}
