// Main Header
.o-header {
  position: relative;
  z-index: 9;
  
  .p-home & {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    color: $white;
  }
  
  .p-producers-list & {
    margin-bottom: 80px;
    
    @include mobile {
      margin-bottom: 100px;
    }

    @include laptop {
      margin-bottom: 0;
    }
  }
  
  &__content {
    position: relative;
    display: flex;

    body:not(.p-home) & {
      @include laptop {
        padding: 20px 0 30px 230px;
      }
    }
  }
}
