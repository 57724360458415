.o-home-trademark {
  background-color: $floral-white-bg;
  overflow: hidden;

  &__inner {
    @include laptop {
      display: flex;
      flex-direction: row;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: 80px 0 100px;
    flex-direction: column;

    @include tablet {
      padding: 120px $mid-inner-content-space * 2 120px $mid-inner-content-space;
    }

    @include laptop {
      flex: 0 0 60%;
    }
  }

  &__image {
    @include webpbgimage("../media/app/home/home-trademark-bg");
    position: relative;
    aspect-ratio: 1.33;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include mobile {
      height: 600px;
    }

    @include laptopDown {
      margin-left: -20px;
      margin-right: -20px;
    }

    @include laptop {
      flex-grow: 1;
      height: auto;
    }

    @include desktop {
      margin-right: $grid-gutter-width-md * (-1);
    }

    @include largeDesktop {
      margin-right: $grid-gutter-width-lg * (-1);
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 50%;
    width: 140px;
    transform: translate(50%, -50%);

    @include tablet {
      width: 180px;
    }

    @include laptop {
      top: 50%;
      right: 100%;
    }
  }

  .o-stackedBlocks & {
    &__inner {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      @include laptop {
        flex-wrap: nowrap;
      }
    }

    &__content,
    &__image {
      flex: 0 0 100%;
      margin: 0;

      @include laptop {
        flex: 1 0 50%;
        order: 0;
      }
    }

    &__content {
      max-width: 600px;
      margin: 0 auto;
      padding: 40px 20px 0;
      order: 1;

      @include laptop {
        flex: 0 0 50%;
        order: 0;
        max-width: 100%;
      }

      .p-producers-list & {
        line-height: 1.4;
      }
    }

    &__image {
      aspect-ratio: 1.33;

      @include laptop {
        height: auto;
      }
    }
  }

  .o-stackedBlocks > .container:nth-child(odd) & {
    &__content {
      @include laptop {
        padding: 20px;
      }

      @include desktop {
        padding: 40px;
      }

      @include largeDesktop {
        padding: 80px;
      }
    }

    &__image {
      @include desktop {
        margin-right: $grid-gutter-width-md * (-1) + 20;
      }

      @include largeDesktop {
        margin-right: $grid-gutter-width-lg * (-1) + 20;
      }
    }
  }

  .o-stackedBlocks > .container:nth-child(even) & {
    &__inner {
      @include laptop {
        direction: rtl;
      }
    }

    &__image {
      @include desktop {
        margin-right: 0;
        margin-left: $grid-gutter-width-md * (-1) + 20;
      }

      @include largeDesktop {
        margin-left: $grid-gutter-width-lg * (-1) + 20;
      }
    }

    &__content {
      @include laptop {
        text-align: left;
        direction: ltr;
      }

      @include desktop {
        padding: 80px;
      }
    }
  }
}
