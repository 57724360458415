html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $body-font-size;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  color: $color-text-neutral;
  
  .container {
    
    @include desktop {
      padding-right: $grid-gutter-width-md;
      padding-left: $grid-gutter-width-md;
    }

    @include largeDesktop {
      padding-right: $grid-gutter-width-lg;
      padding-left: $grid-gutter-width-lg;
    }
  }
  
  .container-from-left {
    padding-left: $grid-gutter-width/2;
    
    @include desktop {
      padding-left: $grid-gutter-width-md;
    }

    @include largeDesktop {
      padding-left: $grid-gutter-width-lg;
    }
  }
}
