// Link
.c-link {

  &:not(.--btn):hover {
    @extend %decoration-underline;
  }

  &.--underlined {
    @extend %decoration-underline;

    &:hover {
      @extend %decoration-none;
    }

  }

  &.--btn {
    @include transition(background-color .3s ease);
    display: inline-block;
    padding: 5px 15px;
    background-color: $brand-primary;
    color: $white;
    font-size: .875em; //14px
    
    &:hover {
      background-color: $brand-primary-hover;
    }
    
    .o-footer__facebook & {
      border-radius: 6px;
    }
  }
}
