// Styles for plain text
.c-txt {
  font-size: 1.25em; //20px
  font-family: $font-family-serif;

  &.--sm {
    font-size: 1em; //16px

    @include mobile {}

    @include laptop {
      font-size: 1.125em; //18px
    }

    @include largeDesktop {}

    @include fhd {
      font-size: 1.125em; //18px
    }
  }

  &.--mdp {
    font-size: 1.125em; //18px

    @include mobile {}

    @include laptop {
      font-size: 1.25em; //20px
    }

    @include largeDesktop {}

    @include fhd {
      font-size: 1.375em; //22px
    }
  }
  
  &.--lg {
    font-size: 1.25em; //20px

    @include mobile {}

    @include laptop {
      font-size: 1.375em; //22px
    }

    @include largeDesktop {}

    @include fhd {
      font-size: 1.5em; //24px
    }
  }
}
