/*
  Helper classes for font families
*/

.ff-base {
  font-family: $font-family-base;
}

.ff-serif {
  font-family: $font-family-serif;
}
