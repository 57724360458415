.o-intro-banner {
  display: flex;
  align-items: flex-end;
  padding-top: 200px;
  padding-bottom: 0;
  margin-top: -1px;
  color: $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .p-home & {
    padding-bottom: 100px;
    min-height: 800px;
  }
  
  .p-about & {
    background-position: center 70%;
  }
  
  @include mobile {
    padding-top: 250px;
  }

  &.no-desc {
    display: flex;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
    
    @include mobile {
      padding-top: 0;
    }
  }

  @include laptop {
    height: 630px;
    
    .p-home & {
      height: 90vh;
    }
  }
}
