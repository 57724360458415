/*
  Helper classes for background color
*/

.bg-grey-light {
  background-color: $grey-light-bg;
}

.bg-primary {
  background-color: $brand-primary;
}
